<div class="footer">
    <div class="container">

        <div class="row">
            <div style="" class="portion first-portion col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <a _ngcontent-chc-c51="" routerlinkactive="active" class="navbar-brand active" ng-reflect-router-link-active="active" ng-reflect-router-link="/" href="/">
                    <img src="/assets/images/Sub-Bidding-Logo.png" class="logo" alt="img">
                </a>
                <div style="display: flex; margin-top: 10px;">

                    <p>A subsidiary of True Bid Data Inc.</p>
                </div>
                <div class="social-icons">
                    <p>
                        <a href="https://www.facebook.com/tbd.truebiddata/" target="_Blank"> <i class="fa fa-facebook"></i></a>
                        <a href="https://www.instagram.com/truebiddata/" target="_Blank"><i class="fa fa-instagram"></i></a>
                        <a href="https://twitter.com/TrueBidData" target="_Blank"> <i class="fa fa-twitter"></i></a>
                        <a href="https://www.linkedin.com/in/true-bid-a47a37196" target="_Blank"> <i class="fa fa-linkedin-square"></i></a>
                        <a href="https://www.youtube.com/channel/UCFGJFwjrbzqdtvY-N7neDAw?view_as=subscriber" target="_Blank"> <i class="fa fa-youtube-play"></i></a>
                        <a href="https://www.pinterest.com/truebiddata/" target="_Blank"> <i class="fa fa-pinterest"></i></a>
                    </p>
                </div>
            </div>
            <div class="portion second-portion col-lg-4 col-md-6 col-sm-12 col-xs-12">


            </div>
            <div class="portion third-portion col-lg-4 col-md-12 col-sm-12 col-xs-12">

                <p><a class="other" [routerLink]="['/contact-us']">Contact Us</a></p>
                <p><a class="other" [routerLink]="['/why-us']">Why Us</a></p>
                <p><a class="other" [routerLink] = "['/about-us']">About Us</a></p>
            </div>

        </div>
        <div class=" copyright-bar ">
            <p>
               &#169; Copyright 2020 True Bid Data Inc. All rights reserved. Version 1.0.0.1
            </p>
        </div>
    </div>

</div>
