export const environment = {
    production: false
}
//
export const apis = {
    baseUrl: 'https://tbd-api.codegenio.com/api',
    googleApiKey: 'AIzaSyBH2NFiXlM-Vt6Z08lo-26AvfyrOV9xvBM',
    googleCaptchaSiteKey: '6LdXHEkfAAAAAAa_EFPKS0U6we8HhiUmWYm8RE48'
}

export const socialLoginUrls = {
    google: `${apis.baseUrl}/public/login/google`,
    facebook: `${apis.baseUrl}/public/login/facebook`
}

export const appURL = 'https://subbidding.codegenio.com'

export const tbdUrl = 'https://tbd.codegenio.com'
